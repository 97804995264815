import QueryString from 'query-string';
import window from 'window-shim';

import APIConstants from 'spa/constants/APIConstants';
import { handleError, handleErrorThrowOnUnauthorized } from './Handlers';
import {
  fetchRequest,
  fetchFileRequest,
  getJSON,
  postJSON,
  patchJSON,
  putJSON,
  deleteJSON,
  postForm,
} from '../utils/FetchUtils';
export default class API {
  static getUser() {
    return getJSON('/api/user')
      .then(handleError)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`unexpected status: ${response.status}`);
      });
  }

  static updateTask({ name, status, taskList }) {
    const payload = {
      name,
      status,
      task_list: taskList,
    };

    return postJSON('/api/task', payload)
      .then(handleError)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`unexpected status: ${response.status}`);
      });
  }

  static getAPIKeys() {
    return getJSON(`${window.config.v4_api_endpoint}/customer/me/api_key`)
      .then(handleError)
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json) => window.Promise.resolve(json.api_keys));
        }
        throw new Error(`unexpected status: ${response.status}`);
      });
  }

  static createAPIKey(name) {
    const payload = {
      name,
    };
    return postJSON(`${window.config.v4_api_endpoint}/customer/me/api_key`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            if (body && body.error) {
              return window.Promise.reject({ error: body.error });
            }
          }
          return window.Promise.resolve(body);
        })
      );
  }

  static deleteAPIKey(id) {
    const payload = {
      is_active: false,
    };
    return patchJSON(`${window.config.v4_api_endpoint}/customer/me/api_key/${id}`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200 || body.is_active !== false) {
            return window.Promise.reject({ error: body.error });
          }
          return window.Promise.resolve(body);
        })
      );
  }

  static getWebhooks() {
    return getJSON(`${window.config.v4_api_endpoint}/customer/me/webhook`)
      .then(handleError)
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json) => window.Promise.resolve(json.webhooks));
        }
        throw new Error(`unexpected status: ${response.status}`);
      });
  }

  static createWebhook(url) {
    const payload = {
      url,
    };
    return postJSON(`${window.config.v4_api_endpoint}/customer/me/webhook`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            if (body && body.error) {
              return window.Promise.reject({ error: body.error });
            }
          }
          return window.Promise.resolve(body);
        })
      );
  }

  static deleteWebhook(id) {
    const payload = {
      is_active: false,
    };
    return deleteJSON(`${window.config.v4_api_endpoint}/customer/me/webhook/${id}`, payload)
      .then(handleError)
      .then((response) => {
        if (response.status !== 204) {
          return response.json().then((body) => window.Promise.reject({ error: body.error }));
        }
        return window.Promise.resolve();
      });
  }

  static getLegacyTransactionById(transId) {
    return getJSON(`/api/transaction/${transId}`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionById(transId) {
    return getJSON(`${window.config.v4_api_endpoint}/transaction/${transId}`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionExtraDetails(transId) {
    return getJSON(`${window.config.v4_api_endpoint}/transaction/${transId}/extra_details`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionPartner(transId) {
    return getJSON(`/api/transaction/${transId}/partner`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static getCheckoutPartner(token, ttoken, transId) {
    const params = new URLSearchParams({
      token,
      ttoken,
      transaction_id: transId,
    });
    return getJSON(`/api/checkout/partner?${params.toString()}`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactions({
    search,
    limit,
    role,
    type,
    amount: { min, max },
    status,
    nextCursor,
    sortBy,
    sortDirection,
  }) {
    const params = new URLSearchParams({
      limit,
      ...(search && { search }),
      ...(min && { min_amount: min }),
      ...(max && { max_amount: max }),
      ...(status && { status }),
      ...(nextCursor && { next_cursor: nextCursor }),
      sort_by: sortBy,
      sort_direction: sortDirection,
      include_actions: true,
    });
    role.map((roleValue) => params.append('role', roleValue));
    type.map((typeValue) => params.append('transaction_type', typeValue));

    return getJSON(`${window.config.internal_endpoint}/transactions/search?${params.toString()}`, {
      // eslint-disable-next-line quote-props
      'Profile': 'true',
    })
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionFeesSummary(transaction) {
    return postJSON(`${window.config.v4_api_endpoint}/transaction/fees-summary`, transaction)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status === 200) {
            return Promise.resolve(body);
          }
          return Promise.reject(body);
        })
      );
  }

  static checkTransactionTour(customerId) {
    const params = new URLSearchParams({
      reference_type: 'customer',
      reference_id: customerId,
      flag_name: 'HasTouredMyTransactionsV2',
      get_all: false,
    });
    return getJSON(`${window.config.internal_endpoint}/flag?${params.toString()}`, {
      'Content-Type': undefined,
    })
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static finishTransactionTour(customerId) {
    const payload = {
      reference_type: 'customer',
      reference_id: customerId,
      flag_name: 'HasTouredMyTransactionsV2',
      note: 'Transaction V2 Toured',
      allow_multiple: false,
    };
    return postJSON(`${window.config.internal_endpoint}/flag`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status === 200) {
            return Promise.resolve(body);
          }
          return Promise.reject(body);
        })
      );
  }

  static createOffer(auctionToken, offerInformation) {
    const payload = offerInformation;
    return postJSON(`${window.config.offer_endpoint}/${auctionToken}/offer`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static createCounterOffer(auctionId, offerInformation) {
    const payload = offerInformation;
    return postJSON(`${window.config.offer_endpoint}/${auctionId}/offer`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static auctionBuyItNow(auctionToken) {
    return postJSON(`/api/auction/${auctionToken}/checkout`, {})
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static getAuctionByToken(auctionToken) {
    return getJSON(`${window.config.offer_endpoint}/${auctionToken}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getAuctionOffers(auctionId, isMasked = false) {
    const link = `${window.config.offer_endpoint}/${auctionId}/offer${
      isMasked ? '?is_masked=true' : ''
    }`;
    return getJSON(link)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static cancelAuction(auctionId) {
    return deleteJSON(`${window.config.offer_endpoint}/${auctionId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static applyActionToOffer(action, auctionId, offerId) {
    const payload = { action }; // action can be one of ['accept', 'reject']
    return patchJSON(`${window.config.offer_endpoint}/${auctionId}/offer/${offerId}`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static performTransactionAction(transactionID, action) {
    return patchJSON(`${window.config.v4_api_endpoint}/transaction/${transactionID}`, {
      action,
    }).then((response) =>
      response.json().then((body) => {
        if (response.status !== 200) {
          return Promise.reject(body.error);
        }
        return Promise.resolve(body);
      })
    );
  }

  static addCustomerDisbursementMethod(payload) {
    return postJSON(`${window.config.v4_api_endpoint}/customer/me/disbursement_methods`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static editCustomerDisbursementMethod(disbursementId, payload) {
    return putJSON(
      `${window.config.v4_api_endpoint}/customer/me/disbursement_methods/${disbursementId}`,
      payload
    )
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static deleteCustomerDisbursementMethod(disbursementId) {
    return deleteJSON(
      `${window.config.v4_api_endpoint}/customer/me/disbursement_methods/${disbursementId}`,
      null
    )
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static addTransactionDisbursementMethod(transId, payload) {
    return postJSON(
      `${window.config.v4_api_endpoint}/transaction/${transId}/disbursement_methods`,
      payload
    )
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static createCheckoutTransaction(payload) {
    return postJSON(window.config.checkout_endpoint, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static createTransaction(payload) {
    return postJSON(`${window.config.v4_api_endpoint}/transaction`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getAbTest(testConfig) {
    return postJSON(`${window.config.internal_endpoint}/ab-test/enrollments`, testConfig)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getPaymentMethods(transactionID) {
    return getJSON(`${window.config.v4_api_endpoint}/transaction/${transactionID}/payment_methods`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getAdyenMethods(transactionID, customerId) {
    return getJSON(
      `${window.config.internal_endpoint}/adyen/${transactionID}/payment_methods/${customerId}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static setPaymentMethod(transactionID, paymentMethod, requestBody = {}) {
    return postJSON(
      `${window.config.v4_api_endpoint}/transaction/${transactionID}/payment_methods/${paymentMethod}`,
      requestBody
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static setTransactionStatus(transactionID, status, requestBody = {}) {
    return patchJSON(`${window.config.internal_endpoint}/transaction/${transactionID}/status`, {
      ...requestBody,
      status,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static submitAdyenPayment(
    transactionID,
    customerID,
    amount,
    paymentData,
    isOutstandingPayment = false,
    returnUrl = null
  ) {
    const params = new URLSearchParams({
      payment_origin: location.pathname + location.search,
      ...(returnUrl && { return_url: returnUrl }),
      ...(isOutstandingPayment && { is_outstanding_payment: true }),
    });
    const payload = {
      ...paymentData,
      is_outstanding_payment: isOutstandingPayment,
      amount,
      return_url: `${
        window.config.www_base_url
      }/adyen/${transactionID}/result/${customerID}?${params.toString()}`,
    };
    return postJSON(
      `${window.config.internal_endpoint}/adyen/${transactionID}/payment/${customerID}`,
      payload
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getUserAdyenMethods() {
    return getJSON(`${window.config.internal_endpoint}/adyen/credit-cards`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static saveAdyenCreditCard(customerID, paymentData) {
    const payload = {
      ...paymentData,
      return_url: `${window.config.www_base_url}/adyen/credit-card/save/${customerID}/result`,
    };
    return postJSON(`${window.config.internal_endpoint}/adyen/credit-cards`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static removeAdyenCreditCard(creditCardId) {
    const payload = { credit_card_id: creditCardId };
    return deleteJSON(`${window.config.internal_endpoint}/adyen/credit-cards`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static authorizeAdyenPayment(transactionID, threeDSResult, isOutstandingPayment) {
    const payload = {
      threeds_result: threeDSResult,
      is_outstanding_payment: isOutstandingPayment,
    };
    return postJSON(
      `${window.config.internal_endpoint}/adyen/${transactionID}/payment/authorize`,
      payload
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static authenticateAdyenPayment(transactionID, customerID, amount, paymentData, returnUrl) {
    const params = new URLSearchParams({
      is_checkout: true,
      ...(returnUrl && { return_url: returnUrl }),
    });
    const payload = {
      ...paymentData,
      amount,
      return_url: `${
        window.config.www_base_url
      }/adyen/${transactionID}/result/${customerID}?${params.toString()}`,
    };
    return postJSON(
      `${window.config.internal_endpoint}/adyen/${transactionID}/payment/authenticate`,
      payload
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static submitBuyerBankDetails(transactionID, requestBody = {}) {
    return postJSON(
      `${window.config.v4_api_endpoint}/transaction/${transactionID}/buyer_payment`,
      requestBody
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getDisbursementMethods() {
    return getJSON(`${window.config.v4_api_endpoint}/customer/me/disbursement_methods`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static setNotificationPreferences(payload, token) {
    if (token !== '') {
      payload.token = token;
    }
    return postJSON(`${window.config.www_base_url}/api/set-notification-preferences`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getNotificationPreferences(token) {
    let queryString;
    if (token !== '') {
      queryString = `?token=${token}`;
    } else {
      queryString = '';
    }
    return getJSON(`${window.config.www_base_url}/api/get-notification-preferences${queryString}`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status === 409) {
            return Promise.resolve(body);
          } else if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static userHasDocumentsSubmitted(custId) {
    return getJSON(`/api/customer/${custId}/documents_submitted_check`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getUserApprovedEKycFields(custId) {
    return getJSON(`/api/customer/${custId}/approved_ekyc_fields`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static submitUserKyc(kycFields) {
    const payload = kycFields;
    return postJSON(window.config.kyc_api_endpoint, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return Promise.resolve();
      });
  }

  static submitUserEKyc(custId, eKycFields) {
    const payload = eKycFields;
    return postJSON(`/api/customer/${custId}/electronic_verification`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getUserEKYCEligibility(custId) {
    return getJSON(`/api/customer/${custId}/electronic_verification/eligibility`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static loginUser(username, password, isSignup = false, turnstileResponse = undefined) {
    const payload = { username, password, isSignup, turnstileResponse };
    const loginPromises = [];
    loginPromises.push(
      postJSON(`/api/auth/login`, payload)
        .then(handleError)
        .then((response) =>
          response.json().then((body) => {
            if (!response.ok) {
              return Promise.reject(body.error);
            }
            document.cookie = `user_has_legacy_buttons=; domain=${window.config.cookie_domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
            return Promise.resolve(body);
          })
        )
    );
    if (['DEV', 'SANDBOX'].includes(window.config.environment) && window.config.www_api_url) {
      // If we're in local dev, we need to send another request to the remote dev server to get the .escrow-dev cookie
      loginPromises.push(
        postJSON(`${window.config.www_api_url}/api/auth/login`, payload)
          .then(handleError)
          .then((response) =>
            response.json().then((body) => {
              if (!response.ok) {
                return Promise.reject(body.error);
              }
              document.cookie = `user_has_legacy_buttons=; domain=${window.config.cookie_domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
              return Promise.resolve(body);
            })
          )
      );
    }
    return Promise.all(loginPromises).then((responses) => Promise.resolve(responses[0]));
  }

  static loginUserByToken(token) {
    const payload = { token };
    return postJSON('/api/auth/login/sso', payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          document.cookie = `user_has_legacy_buttons=; domain=${window.config.cookie_domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
          return Promise.resolve(body);
        })
      );
  }

  static checkIfEmailExist(email) {
    return postJSON('/api/email/check_if_customer_email_used', { email: email })
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body);
          }
          return Promise.resolve(body.email_used);
        })
      );
  }

  static signupUser(username, password, additionalFields = {}) {
    const signupData = {
      CompanyName: '',
      WebsiteUrl: '',
      BriefDescription: '',
      MonthlySalesRevenue: '',
      ShowCompanyNameInsteadOfName: false,
      FirstName: '',
      MiddleName: '',
      LastName: '',
      EmailAddress: username,
      ConfirmEmailAddress: username,
      Pwd: password,
      ConfirmPwd: password,
      MotherMaidenName: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      OtherState: '',
      ZipCode: '',
      Country: '',
      Phone: '',
      CountryCode: '',
      PaymentsToCompanyName: false,
      IsPartner: false,
      ...additionalFields,
    };
    const payload = {
      registrationModel: JSON.stringify(signupData),
    };
    return postJSON(window.config.signup_api_endpoint, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok || !body) {
            return Promise.reject({
              type: APIConstants.UNHANDLED,
              errors: 'technical-difficulties',
            });
          } else if (!body.HasData) {
            return Promise.reject({
              type: APIConstants.UNHANDLED,
              errors: 'incomplete-form',
            });
          } else if (body.ClientData && body.ClientData.UserExist) {
            return Promise.reject({
              type: APIConstants.UNHANDLED,
              errors: 'email-already-exists',
            });
          }
          return Promise.resolve(body);
        })
      );
  }

  static updateCustomerDetails(customerID, customerDetails) {
    const payload = customerDetails;
    return postJSON(`${window.config.customer_api_endpoint}/${customerID}`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.message);
          }
          return Promise.resolve(body);
        })
      );
  }

  static upgradeDraftToTransaction(payload, token) {
    return postJSON(`${window.config.checkout_endpoint}/${token}`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionByToken(token) {
    return getJSON(`${window.config.checkout_endpoint}/${token}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static patchCheckoutBuyer(token, email) {
    const payload = {
      action: 'modify_buyer',
      modify_buyer_information: {
        party: {
          role: 'buyer',
          agreed: true,
          customer: email,
        },
      },
    };
    return patchJSON(`${window.config.checkout_endpoint}/${token}`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getCustomerDetails() {
    return getJSON('/api/customer')
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static postCreditCardInformation(data, transactionId) {
    const formData = Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
    return postForm(
      `${window.config.my_escrow_base_url}/myescrow/CreditCardPayment.asp?tran=${transactionId}`,
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'cors',
      }
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) => response);
  }

  static validateTaxNumber(type, number) {
    const payload = {
      type,
      number,
    };
    return postJSON(`${window.config.v4_api_endpoint}/tax_number/validate`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static postTaxNumber(type, number, validateOnly = false) {
    const payload = {
      type,
      number,
    };
    const headers = {
      'validate-only': String(validateOnly),
    };
    return postJSON(`${window.config.v4_api_endpoint}/customer/me/tax_number`, payload, headers)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getV4CustomerMe() {
    return getJSON(`${window.config.v4_api_endpoint}/customer/me`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getV4Customer(custId) {
    return getJSON(`${window.config.v4_api_endpoint}/customer/${custId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static deleteTaxNumber(taxNumberId) {
    return deleteJSON(`${window.config.v4_api_endpoint}/customer/me/tax_number/${taxNumberId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static chargeCreditCard(transactionId, creditCardData, isOutstandingPayment = false) {
    const payload = creditCardData;
    if (isOutstandingPayment) {
      payload.is_outstanding_payment = isOutstandingPayment;
    }
    return postJSON(`/api/transaction/${transactionId}/fund/credit-card`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static chargeSavedCreditCard(transactionId, cardId, isOutstandingPayment = false) {
    return postJSON(`/api/transaction/${transactionId}/fund/credit-card/${cardId}`, {
      is_outstanding_payment: isOutstandingPayment,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPlaidIdentity(accountId) {
    return fetchRequest('GET', `/api/plaid/account/${accountId}/identity`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static checkPlaidAccountValidity(accountId) {
    return fetchRequest('GET', `/api/plaid/account/${accountId}/check`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getSavedPlaidAccounts() {
    return fetchRequest('GET', `/api/plaid/saved`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }
  static getPlaidLinkToken(accountId) {
    return postJSON(`/api/plaid/link_token`, { account_id: accountId })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }
  static getPlaidAccessToken(publicToken, selectedAccounts, institution) {
    return postJSON(`/api/plaid/public_token`, {
      public_token: publicToken,
      selected_accounts: selectedAccounts,
      institution: institution,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getWireDetails(transid) {
    return getJSON(
      `${window.config.v4_api_endpoint}/transaction/${transid}/payment_methods/wire_transfer`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getLastBuyerBankEntry(custId) {
    return fetchRequest(
      'GET',
      `${window.config.internal_endpoint}/customer/${custId}/buyer_bank?is_latest=true`,
      null
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response
          .json()
          .then((jsonBody) => (response.ok ? Promise.resolve(jsonBody) : Promise.reject(jsonBody)))
      );
  }

  static getCheckDetails(transid) {
    return getJSON(`${window.config.v4_api_endpoint}/transaction/${transid}/payment_methods/check`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getExtendedKycRequirement(customerId) {
    return getJSON(`/api/customer/${customerId}/extended_kyc`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static submitPartnerLogos(customerId, submissionIds) {
    const payload = {
      submission_ids: submissionIds,
    };

    return postJSON(`/api/customer/${customerId}/logo`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static listPartnerLogos(customerId) {
    return getJSON(`/api/customer/${customerId}/logo`)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartnerLogoSettings(customerId) {
    return getJSON(`/api/customer/${customerId}/logo/settings`)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static updatePartnerLogoSettings(customerId, fields) {
    return patchJSON(`/api/customer/${customerId}/logo/settings`, fields)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static removePartnerLogo(customerId, submissionId) {
    return deleteJSON(`/api/customer/${customerId}/logo/${submissionId}`)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getDocumentsRequested(transactionId) {
    return getJSON(`/api/additional-docs/requests?trans_id=${transactionId}`)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static submitDocument(submissionId, s3ObjectId) {
    const payload = {
      submission_id: submissionId,
      s3_object_id: s3ObjectId,
    };
    return postJSON(`/api/additional-docs/requests`, payload)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCardById(cardId) {
    return getJSON(`/api/credit-card/${cardId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static chargeVerificationAmounts(cardId, transId) {
    const payload = {
      transaction_id: transId,
    };

    return postJSON(`/api/credit-card/${cardId}/trigger-verification`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static verifyCardVerificationAmounts(cardId, transId, firstCharge, secondCharge) {
    const payload = {
      transaction_id: transId,
      first_charge_amount: firstCharge,
      second_charge_amount: secondCharge,
    };

    return postJSON(`/api/credit-card/${cardId}/perform-verification`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCardVerificationDetails(chargeId) {
    return getJSON(`/api/credit-card/charge/${chargeId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCardVerificationDetailsByTransactionId(transactionId) {
    return fetchRequest('GET', `/api/credit-card/charge?transaction_id=${transactionId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static sendEscrowPayBuyerReminder(transactionId) {
    return postJSON(`/api/checkout/remind-buyer`, {
      transaction_id: transactionId,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static sendBuyerCreatePasswordEmail(transactionId) {
    return postJSON(`/api/checkout/send-create-password`, {
      transaction_id: transactionId,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCustSavedCreditCards(customerId, currency = null, isSaved = null, isValid = null) {
    const query = {};
    if (currency) {
      query.currency = currency;
    }
    if (isSaved !== null) {
      query.is_saved = isSaved;
    }
    if (isValid) {
      query.is_valid = isValid;
    }

    return fetchRequest(
      'GET',
      `/api/customer/${customerId}/credit-card?${QueryString.stringify(query)}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static saveCreditCard(customerId, creditCardData) {
    return postJSON(`/api/customer/${customerId}/credit-card`, creditCardData)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static removeCreditCard(customerId, creditCardId) {
    const payload = { card_id: creditCardId };
    return deleteJSON(`/api/customer/${customerId}/credit-card`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static sendDomainReportRequest(email) {
    return postJSON(`/api/domain-report-request`, { customer_email: email })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static submitPlaidPayment(transId, plaidAccountId, isOutstandingPayment = false) {
    return postJSON(`/api/transaction/${transId}/fund/plaid/${plaidAccountId}`, {
      isOutstandingPayment,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static submitPoliPayment(transId, userFlow, isOutstandingPayment = false) {
    const payload = {
      user_flow: userFlow,
    };
    if (isOutstandingPayment) {
      payload.is_outstanding_payment = isOutstandingPayment;
    }
    return postJSON(`/api/transaction/${transId}/fund/poli`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getReturnURLFromToken(token) {
    return getJSON(`${window.config.internal_endpoint}/landing-page-token/${token}/return-url`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartnerTransactions(payload) {
    return getJSON(
      `${window.config.v4_api_endpoint}/partner/transactions?${QueryString.stringify(payload)}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getTransactionStatistics(dataType, interval, dateStart, dateEnd, filters = {}) {
    const query = {
      data_type: dataType,
      interval,
      date_start: dateStart,
      date_end: dateEnd,
      ...filters,
    };

    return fetchRequest(
      'GET',
      `${
        window.config.v4_api_endpoint
      }/partner/statistics/transactions/count?${QueryString.stringify(query)}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartnerCustomers(payload) {
    return getJSON(
      `${window.config.v4_api_endpoint}/partner/customers?${QueryString.stringify(payload)}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartnerReports(payload) {
    return getJSON(
      `${window.config.v4_api_endpoint}/partner/reports?${QueryString.stringify(payload)}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static generatePartnerReports(filters, filterType) {
    const {
      initiation_start_date: initiationStartDate,
      initiation_end_date: initiationEndDate,
      verification_status: verificationStatus,
      closed_start_date: closedStartDate,
      closed_end_date: closedEndDate,
      status,
    } = filters;
    const payload = {
      [filterType]: {},
    };
    if (initiationStartDate) {
      payload[filterType].initiation_start_date = initiationStartDate[0];
    }
    if (initiationEndDate) {
      payload[filterType].initiation_end_date = initiationEndDate[0];
    }
    if (closedStartDate) {
      payload[filterType].closed_start_date = closedStartDate[0];
    }
    if (closedEndDate) {
      payload[filterType].closed_end_date = closedEndDate[0];
    }
    if (status) {
      payload[filterType].status = status[0];
    }
    if (verificationStatus) {
      payload[filterType].verification_status = verificationStatus[0];
    }

    return postJSON(`${window.config.v4_api_endpoint}/partner/reports`, payload)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartnerReportsCount() {
    return getJSON(`${window.config.v4_api_endpoint}/partner/reports/count`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static downloadPartnerReport(taskId, asJson = false) {
    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/partner/reports/${taskId}/download?as_json=${asJson}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCustomerStatistics(dataType, interval, dateStart, dateEnd, filters = {}) {
    const query = {
      data_type: dataType,
      interval,
      date_start: dateStart,
      date_end: dateEnd,
      ...filters,
    };

    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/partner/statistics/customers/count?${QueryString.stringify(
        query
      )}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPartneredCustomerDetailsByPartyId(partyId) {
    return getJSON(`${window.config.v4_api_endpoint}/partner/customer?party_id=${partyId}`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCustomerPaymentsIn(customerId) {
    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/partner/customer/${customerId}/payments_in`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCustomerPaymentsOut(customerId) {
    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/partner/customer/${customerId}/payments_out`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getLandingPageTokenCustomer(token) {
    return getJSON(`api/landing-page-token/${token}/customer`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getLandingPageTokenReturnUrl(token) {
    return getJSON(`api/landing-page-token/${token}/return-url`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static isUpgradedPartnerToken(token) {
    return getJSON(`api/landing-page-token/${token}/freelancer/check`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static updateCustomerV4(body) {
    return patchJSON(`${window.config.v4_api_endpoint}/customer/me`, body)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getTransactionTimelineEntries(transId) {
    return getJSON(`${window.config.v4_api_endpoint}/transaction/${transId}/timeline-entries`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getCustomerNationalDocuments() {
    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/customer/me/identification-documents`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getOutstandingBalance(transactionId) {
    return fetchRequest(
      'GET',
      `${window.config.internal_endpoint}/transactions/${transactionId}/balance`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }

  static getPaypalPortal(transactionId, returnUrl, redirectType) {
    const params = new URLSearchParams({
      ...(returnUrl && { return_url: returnUrl }),
      ...(redirectType && { redirect_type: redirectType }),
    }).toString();

    const request = `${
      window.config.v4_api_endpoint
    }/transaction/${transactionId}/payment_methods/paypal${params ? `?${params}` : ``}`;

    return getJSON(request)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static shouldCombineBrokerAndEscrowFee(transactionId) {
    const request = `/api/transaction/${transactionId}/combine-broker-fee`;

    return getJSON(request)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static paypalSuccess(transactionId, paypalRef) {
    return postJSON('/api/paypal/success', {
      transaction_id: transactionId,
      paypal_reference: paypalRef,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static paypalCaptureOrder(orderId, transactionId) {
    return postJSON('/api/paypal/order/capture', {
      order_id: orderId,
      transaction_id: transactionId,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionProgress(transactionId) {
    return getJSON(`/api/transaction/${transactionId}/progress`).then((response) =>
      response.json().then((jsonBody) => {
        if (!response.ok) {
          return Promise.reject(jsonBody);
        }
        return Promise.resolve(jsonBody);
      })
    );
  }

  static checkRegistered2fa() {
    return getJSON(`${window.config.internal_endpoint}/2fa/types`).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static registerAuthApp2fa() {
    return postJSON(`${window.config.internal_endpoint}/2fa/types/authenticator`).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static registerSMS2fa(phone) {
    return postJSON(`${window.config.internal_endpoint}/2fa/types/sms`, {
      phone,
      replace: true,
    }).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static validate2fa(code2fa, type2fa) {
    return postJSON(`${window.config.internal_endpoint}/2fa/types/${type2fa}/challenge`, {
      code_2fa: code2fa,
    }).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static disable2fa(code2fa, type2fa) {
    return deleteJSON(`${window.config.internal_endpoint}/2fa/types/${type2fa}`, {
      code_2fa: code2fa,
    }).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static sendSMS2fa() {
    return getJSON(`${window.config.internal_endpoint}/2fa/types/sms/challenge`).then((response) =>
      response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return Promise.resolve(json);
      })
    );
  }

  static verifyRecaptchaToken(token) {
    return postJSON('/api/recaptcha/verify', { token })
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static getTransactionHoldingPenConditions(transactionId) {
    return getJSON(`${window.config.internal_endpoint}/transaction/${transactionId}/holding-pen`)
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }

  static getOlarkKycUrl() {
    return getJSON('/api/olark/kyc')
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject(body.error);
          }
          return Promise.resolve(body);
        })
      );
  }
  static getCheckoutLabels(transactionId, token) {
    const searchParams = transactionId ? { transaction_id: transactionId } : { token };
    const params = new URLSearchParams(searchParams);
    return getJSON(`/api/checkout/labels?${params.toString()}`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }
  static sendVerificationEmail() {
    return postJSON(`${window.config.internal_endpoint}/email_verification`)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }
  static uploadKYCFile(file) {
    return fetchFileRequest('POST', `${window.config.v4_api_endpoint}/customer/me/verify/file`, {
      file: file,
    })
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }
  static verifyCustomer(kycData) {
    return postJSON(`${window.config.v4_api_endpoint}/customer/me/verify`, kycData)
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }
  static getTransactionProgressDetails(transactionToken) {
    return fetchRequest('GET', `/api/transaction_progress/${transactionToken}`)
      .then(handleError)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }
  static getCustomerVerificationStatus(email) {
    return fetchRequest(
      'GET',
      `${window.config.v4_api_endpoint}/customer/verification_status?${QueryString.stringify({
        email: encodeURIComponent(email),
      })}`
    )
      .then(handleErrorThrowOnUnauthorized)
      .then((response) =>
        response.json().then((jsonBody) => {
          if (!response.ok) {
            return Promise.reject(jsonBody);
          }
          return Promise.resolve(jsonBody);
        })
      );
  }
  static createStripePaymentLink(transactionID) {
    return postJSON(`${window.config.internal_endpoint}/stripe/${transactionID}/payment_link`, {})
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (!response.ok) {
            return Promise.reject({ error: body.error });
          }
          return Promise.resolve(body);
        })
      );
  }

  static approvePaymentViaIntegrationHelper(transactionId, transactionAmount, paymentMethod) {
    const payload = {
      method: paymentMethod,
      amount: transactionAmount,
    };

    return postJSON(
      `${window.config.integration_helper_endpoint}/v1/transaction/${transactionId}/payments_in`,
      payload
    )
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 200) {
            return Promise.reject({ body });
          }
          return Promise.resolve(body);
        })
      );
  }

  static submitKYCViaIntegrationHelper(customerID, accountType) {
    let isPersonal = accountType === 'personal';
    const isCompany = accountType === 'company';
    if (isCompany) isPersonal = true;

    const payload = {
      personal: isPersonal,
      company: isCompany,
      auto_approve: true,
    };

    return postJSON(
      `${window.config.integration_helper_endpoint}/v1/customer/${customerID}/verification`,
      payload
    )
      .then(handleError)
      .then((response) =>
        response.json().then((body) => {
          if (response.status !== 201) {
            return Promise.reject({ body });
          }
          return Promise.resolve(body);
        })
      );
  }
}
